"use client"

import { PropsWithChildren } from "react"
import LogoStyled from "../_components/icons/logo"

export default function AuthLayout(props: PropsWithChildren) {
    return (
        <div className="flex flex-col pt-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mx-auto flex h-10 w-auto items-center justify-center">
                    <LogoStyled />
                </div>
            </div>
            {/* <div className="self-end p-4">
                {session.data && `Logged in as ${session.data.user.name} `}
                <Link
                    href={"/sign-out"}
                    className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                    {session.data ? "Sign out" : "Sign in"}
                </Link>
            </div> */}
            {props.children}
        </div>
    )
}

// check in + check out
// access logs
// visitors
// guest access
// property - log and keysafe
// contractors - just the business
// hazard + incident on checkout

// guided tour
//
